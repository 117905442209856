<template>
  <v-container v-if="isReady">
    <div class="mb-4">
      <v-stepper flat>
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="calculatedStep >= 1"
            color="green"
          >
            Requested Change
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="2"
            :complete="calculatedStep >= 2"
            color="green"
          >
            Partner Approvals
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="3"
            :complete="calculatedStep >= 3"
            color="green"
          >
            Coordinator Approval
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="4"
            :complete="calculatedStep >= 4"
            color="green"
          >
            Approved
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </div>
    <v-row>
      <v-col cols="8">
        <div>
          <v-card>
            <v-card-title>Modification for {{ active.iri }}</v-card-title>
            <v-card-text>
              <div class="d-flex flex-column flex-sm-row">
                <div class="flex-grow-1 pt-2 pa-sm-2">
                  <v-row>
                    <v-col cols="6">
                      <h4>Old State</h4>
                      <component :is="typeComponent" :object="active.oldObject"/>
                    </v-col>
                    <v-col cols="6">
                      <h4>New State</h4>
                      <component :is="typeComponent" :object="active.newObject"/>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="mt-4">
            <v-card-text>
              <h4>Explanation</h4>
              <span>{{ active.explanation }}</span>
            </v-card-text>
          </v-card>
        </div>
      </v-col>

      <v-col cols="4">

        <div v-if="canVote" class="d-flex justify-space-between">
          <v-btn class="mb-4" color="#81C784" style="width: 49%; height: 50px">APPROVE</v-btn>
          <v-btn class="mb-4" color="#E57373" style="width: 49%; height: 50px">DECLINE</v-btn>
        </div>

        <v-card
          class="mx-auto"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">
                Project partners
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-send</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Project partners

              <div v-for="(approver, index) in active.properties.projectPartners" v-bind:key="index + 'apr'" class="pt-2">
                {{ approver }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ChangeDetails",
  components: {},
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('change', ['active']),
    canVote() {
      return false;
    },
    isReady() {
      return typeof this.active !== "undefined";
    },
    typeComponent() {
      return () => import('../components/types/' + _.upperFirst(this.active.type) + '.vue');
    },
    calculatedStep() {
      switch (this.active.status) {
        case 'draft':
          return 1;
        case 'approved':
          return 4;
        default:
          return 0;
      }
    }
  }
};
</script>

<style>

.diff {
  padding: 6px;
  border: 2px solid #646464;
}

.diff > ins {
  background-color: #cef8c2;
}

.diff > del {
  background-color: #ffbaba;
}
</style>
